import React from 'react'

interface Props {
    Icon: any
    href: string
}

const socialLink = ({
    Icon,
    href,
    ...props
} : Props) => (
    <a
        href={href}
        className="group"
        {...props}
    >
        <Icon className="h-6 w-6 fill-zinc-500 transition group-hover:fill-zinc-600 dark:fill-zinc-400 dark:group-hover:fill-zinc-300" />
    </a>
)

export default socialLink
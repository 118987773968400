
import React from 'react'
import images from '../data/images'

const photos = () => {
    let rotations = ['rotate-2', '-rotate-2', 'rotate-2', 'rotate-2', '-rotate-2']

    return (
        <div className="mt-16 sm:mt-20">
            <div className="-my-4 flex justify-center gap-5 py-4 sm:gap-8">
                {images.map((image, idx) => (
                    <div
                        key={idx}
                        className={`
                            ${rotations[idx % rotations.length]}
                            relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 sm:w-72 sm:rounded-2xl
                        `}
                    >
                        <img
                            src={image}
                            alt=""
                            sizes="(min-width: 640px) 18rem, 11rem"
                            className="absolute inset-0 h-full w-full bg-cover bg-center"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default photos
import React from 'react'
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter, 
    RouterProvider 
} from "react-router-dom";

import Home from './Pages/Home'
import About from './Pages/About'
import Credentials from './Pages/Credentials'
import Projects from './Pages/Projects'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/credentials',
        element: <Credentials />
    },
    {
        path: '/projects',
        element: <Projects />
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
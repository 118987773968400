import React, { PropsWithChildren } from 'react'
import { Link } from "react-router-dom"

interface Props {
    href : string
}

const navItem = ({
    href,
    children
} : PropsWithChildren<Props>) => {
    const isActive = window.location.pathname === href

    return (
        <li>
            <Link
                to={href}
                className={`
                    ${isActive?
                        'text-teal-500 dark:text-teal-400'
                        :
                        'hover:text-teal-500 dark:hover:text-teal-400'
                    }
                    relative block px-3 py-2 transition
                `}
            >
                {children}
                {isActive && (
                    <span className="absolute inset-x-1 -bottom-px h-px bg-gradient-to-r from-teal-500/0 via-teal-500/40 to-teal-500/0 dark:from-teal-400/0 dark:via-teal-400/40 dark:to-teal-400/0" />
                )}
            </Link>
        </li>
    )
}

export default navItem
const experiences = [
    {
        org: 'Tunas Bangsa Student Council',
        title: 'Secretary',
        logo: '/storage/images/logo-tb.png',
        start: '2017',
        end: '2018'
    },
    {
        org: 'UNM Computer Science Society',
        title: 'Junior Committee',
        logo: '/storage/images/unm-css.png',
        start: '2020',
        end: '2021'
    },
    {
        org: 'UNM Computer Science Society',
        title: 'President',
        logo: '/storage/images/unm-css.png',
        start: '2021',
        end: '2022'
    }
]
export default experiences
import React from 'react'
import NavItem from './NavItem'
import pageLinks from '../data/pageLinks'
import { PageLink } from '../types'

const desktopNav = (props : Record<string, any>) => (
    <nav {...props}>
        <ul className="flex rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10">
            {pageLinks.map((
                link : PageLink,
                idx : number
            ) => (
                <NavItem
                    key={idx}
                    href={link.url}
                >
                    <span className='capitalize'>
                        {link.name}
                    </span>
                </NavItem>
            ))}
        </ul>
    </nav>
)

export default desktopNav
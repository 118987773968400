import React, { PropsWithChildren } from 'react'

interface Props {
    className?: string
    shadowInset: string
}

const card = ({
    className,
    shadowInset,
    children
} : PropsWithChildren<Props>) => (
    <div className={`${className} group relative`}>
        <div className='relative z-10'>
            {children}
        </div>
        <div className={`${shadowInset} absolute z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:rounded-2xl`}></div>
    </div>
)

export default card
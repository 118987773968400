import React from 'react'
import { BsTrophy, BsAward, BsJournalText } from 'react-icons/bs'
import { SlGraduation } from 'react-icons/sl'

const achievements = [
    {
        icon: <BsTrophy className='w-full text-zinc-600 dark:text-white' />,
        desc: 'Placed third in the 2016 Regional Computer Olympiad during high school.',
        date: 'Mar 2016'
    },
    {
        icon: <BsTrophy className='w-full text-zinc-600 dark:text-white' />,
        desc: 'Placed first in the 2017 Regional Computer Olympiad during high school.',
        date: 'May 2017'
    },
    {
        icon: <BsAward className='w-full text-zinc-600 dark:text-white' />,
        desc: 'Received a certificate of excellence from Tunas Bangsa High School for achieving a perfect score on the national math exam.',
        date: 'May 2018'
    },
    {
        icon: <SlGraduation className='w-full text-zinc-600 dark:text-white' />,
        desc: 'Graduated from Tunas Bangsa highschool.',
        date: 'Jun 2018'
    },
    {
        icon: <BsTrophy className='w-full text-zinc-600 dark:text-white' />,
        desc: "Secured second place in the 2021 Nottingham's Human-Computer Interaction Design Challenge.",
        date: 'Apr 2021'
    },
    {
        icon: <BsJournalText className='w-full text-zinc-600 dark:text-white' />,
        desc: "Published an article titled 'Intent Matching based Customer Services Chatbot with Natural Language Understanding' in the IEEE journal",
        date: 'Dec 2021'
    },
    {
        icon: <SlGraduation className='w-full text-zinc-600 dark:text-white' />,
        desc: 'Graduated from University of Nottingham Malaysia',
        date: 'Jun 2022'
    },
]

export default achievements

import React from 'react'
import PageLayout from '../Layouts/PageLayout'
import Container from '../Components/Container'
import AboutSocialLink from '../Components/AboutSocialLink'
import { InstagramIcon, GitHubIcon, MailIcon } from '../Components/Icons'
import { instagram, gitlab } from '../data/socials'

const about = () => (
    <PageLayout
        title='About'
    >
        <Container className="md:mt-12">
            <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div className="lg:pl-20">
                    <div className="max-w-xs px-2.5 lg:max-w-none">
                        <img
                            src='/storage/images/about.jpg'
                            alt=""
                            sizes="(min-width: 1024px) 32rem, 20rem"
                            className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
                        />
                    </div>
                </div>
                <div className="lg:order-first lg:row-span-2">
                    <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                        From Curiosity to Career: My Journey as an Engineer
                    </h1>
                    <div className="mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                        <p>
                            As long as I can remember, I have always been fascinated by the world of computers.
                            Growing up, I was constantly intrigued by the endless possibilities that technology
                            could offer, and I knew that I wanted to be a part of it. I soon found myself drawn
                            to the challenge of writing code and the satisfaction that came from creating something new.
                        </p>
                        <p>
                            Despite my love of coding, I initially pursued a different career path, dreaming of
                            becoming a doctor. However, as I got closer to the realities of medical school, I
                            realized that the sight of sharp objects made me uneasy. This realization led me back
                            to my first love - computers.
                        </p>
                        <p>
                            As I began to explore the field of programming in more depth, I discovered a particular
                            interest in mobile and web development. The idea of creating solutions that could be
                            accessed by people all over the world, using devices they carry with them everywhere,
                            was incredibly exciting to me. However, my interest in programming extends far beyond
                            these specific areas, and I am always eager to learn more about new technologies,
                            languages, and platforms.
                        </p>
                        <p>
                            Today, as a full stack web engineer, I am grateful for the opportunities that my passion
                            for programming has brought me. My journey has been one  of exploration, experimentation,
                            and continuous learning. I am excited to continue pushing the boundaries of what is possible
                            with programming, and to use my skills to make a meaningful impact on the world around me.
                        </p>
                    </div>
                </div>
                <div className="lg:pl-20">
                    <ul>
                        <AboutSocialLink href={instagram} Icon={InstagramIcon} className="mt-4">
                            Follow on Instagram
                        </AboutSocialLink>
                        <AboutSocialLink href={gitlab} Icon={GitHubIcon} className="mt-4">
                            Follow on GitLab
                        </AboutSocialLink>
                        <AboutSocialLink
                            href="mailto:alvinchaidrata.gmail.com"
                            Icon={MailIcon}
                            className="mt-8 border-t border-zinc-300 pt-8 dark:border-zinc-700/40"
                        >
                            alvinchaidrata.gmail.com
                        </AboutSocialLink>
                    </ul>
                </div>
            </div>
        </Container>
    </PageLayout>
)

export default about
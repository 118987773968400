import React, { PropsWithChildren } from 'react'

interface Props {
    className?: string
}

const circleContainer = ({
    className,
    children,
} : PropsWithChildren<Props>) => (
    <div className={`
        ${className}
        rounded-full flex items-center justify-center shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5
        dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0
    `}>
        {children}
    </div>
)

export default circleContainer
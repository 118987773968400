import React, { PropsWithChildren } from 'react'

interface Props {
    className? : string
    style? : Record<string, any>
}

const container = ({ className, style, children }: PropsWithChildren<Props>) => (
    <div
        className={`${className} w-full mx-auto max-w-2xl lg:max-w-5xl`}
        style={style}
    >
        {children}
    </div>
)

export default container
import React from 'react'
import { Link } from "react-router-dom"
import { PageLink } from '../types'
import pageLinks from '../data/pageLinks'
import Container from './Container'

const footer = () => {
    const url = window.location.pathname

    return (
        <footer className="mt-32">
            <Container>
                <div className="border-t border-zinc-100 pb-16 pt-10 dark:border-zinc-700/40">
                    <div className='relative px-4 sm:px-8 lg:px-12'>
                        <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                            <div className="flex gap-6 text-sm font-medium text-zinc-800 dark:text-zinc-200">
                                {pageLinks.map((
                                    link : PageLink,
                                    idx : number
                                ) => (
                                    <Link
                                        key={idx}
                                        to={link.url}
                                        className={`
                                            ${url === link.url ?
                                                'text-teal-400'
                                                :
                                                'hover:text-teal-500 dark:hover:text-teal-400'
                                            }
                                            capitalize transition
                                        `}
                                    >
                                        {link.name}
                                    </Link>
                                ))}
                            </div>
                            <p className="text-sm text-zinc-400 dark:text-zinc-500">
                                &copy; {new Date().getFullYear()} Alvin Chaidrata. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default footer
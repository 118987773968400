import React from 'react'
import PageLayout from '../Layouts/PageLayout'
import Container from '../Components/Container'
import Timeline from '../Components/Timeline'
import Experiences from '../Components/Experiences'

const credentials = () => (
    <PageLayout
        title='Credentials'
    >
        <Container>
            <div className='max-w-2xl'>
                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Academic and Professional Background
                </h1>
                <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                    Proudly sharing my accomplishments and experiences to demonstrate my
                    commitment to exceptional results.
                </p>
            </div>
        </Container>
        <Container className="mt-12 md:mt-20">
            <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="flex flex-col">
                    <Timeline />
                </div>
                <div className="space-y-10 lg:pl-16 xl:pl-24">
                    <Experiences />
                </div>
            </div>
        </Container>
    </PageLayout>
)

export default credentials

import React from 'react'
import Container from './Container'
import DesktopNav from './DesktopNav'
import AvatarContainer from './AvatarContainer'
import Avatar from './Avatar'
import ModeButton from './ModeButton'
import MobileNav from './MobileNav'

const header = () => (
    <header className="pt-7 pb-16 md:pb-20">
        <Container>
            <div className="relative flex items-center gap-4">
                <div className="flex flex-1">
                    <AvatarContainer>
                        <Avatar />
                    </AvatarContainer>
                </div>
                <div className="flex flex-1 justify-end md:justify-center">
                    <MobileNav className="pointer-events-auto md:hidden" />
                    <DesktopNav className="pointer-events-auto hidden md:block" />
                </div>
                <div className="flex justify-end md:flex-1">
                    <div className="pointer-events-auto">
                        <ModeButton />
                    </div>
                </div>
            </div>
        </Container>
    </header>
)

export default header
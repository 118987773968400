import React from 'react'
import PageLayout from '../Layouts/PageLayout'
import Container from '../Components/Container'
import { InstagramIcon, GitHubIcon, MailIcon } from '../Components/Icons'
import SocialLink from '../Components/SocialLink'
import Photos from '../Components/Photos'
import Resume from '../Components/Resume'
import CircleContainer from '../Components/CircleContainer'
import Card from '../Components/Card'
import techStacks from '../data/techStacks'
import { instagram, gitlab } from '../data/socials'

const home = () => (
    <PageLayout
        title='Home'
    >
        <Container>
            <div className='max-w-2xl'>
                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Full Stack Web Engineer, Tech Enthusiast, and Explorer.
                </h1>
                <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                    I'm Alvin, a full stack web engineer based in Indonesia. With a passion for technology
                    and an insatiable curiosity for exploration, I specialize in creating custom web solutions
                    that deliver exceptional user experiences.
                </p>
            </div>
            <div className="mt-6 flex gap-6">
                <SocialLink
                    href={instagram}
                    aria-label="Follow on Instagram"
                    Icon={InstagramIcon}
                />
                <SocialLink
                    href={gitlab}
                    aria-label="Follow on GitLab"
                    Icon={GitHubIcon}
                />
                <SocialLink
                    href='mailto:alvinchaidrata.gmail.com'
                    aria-label="Email"
                    Icon={MailIcon}
                />
            </div>
            <Photos />
        </Container>
        <Container className="mt-20 md:mt-28">
            <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="flex flex-col">
                    <h1 className="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                        Tech stacks I specialize in
                    </h1>

                    <div className="mt-8 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2">
                        {techStacks.map((stack, stackIdx) => (
                            <Card
                                key={stackIdx}
                                shadowInset='-inset-4'
                            >
                                <div className='flex space-x-4 items-center'>
                                    <CircleContainer className='w-16 h-16 shrink-0'>
                                        <img src={stack.logo} alt="" className='w-full rounded-full' />
                                    </CircleContainer>
                                    <h1 className="text-lg font-semibold text-zinc-800 dark:text-zinc-100">
                                        {stack.name}
                                    </h1>
                                </div>
                            </Card>
                        ))}
                    </div>
                    <div className='flex -space-x-5'>

                    </div>
                </div>
                <div className="space-y-10 lg:pl-16 xl:pl-24">
                    <Resume />
                </div>
            </div>
        </Container>
    </PageLayout>
)

export default home
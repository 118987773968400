import React, { PropsWithChildren } from 'react'
import { Link } from "react-router-dom"
import { Popover } from '@headlessui/react'

interface Props {
    href : string
    currentUrl : string
}

const mobileNavItem = ({
    href,
    currentUrl,
    children
} : PropsWithChildren<Props>) => (
    <li>
        <Popover.Button
            as={Link}
            to={href}
            className={`
                ${currentUrl === href?
                    'text-teal-500 dark:text-teal-400'
                    :
                    'hover:text-teal-500 dark:hover:text-teal-400'
                }
                block py-2 transition
            `}
        >
            {children}
        </Popover.Button>
    </li>
)

export default mobileNavItem
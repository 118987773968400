import React, { PropsWithChildren } from 'react'
import '../css/app.css'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

interface Props {
    title: string
}

const layout = ({ title, children }: PropsWithChildren<Props>) => {

    return (
        <React.StrictMode>
            <div className='w-full min-h-screen bg-zinc-100 dark:bg-zinc-900 overflow-x-hidden'>
                <div className='mx-auto w-full max-w-7xl px-4 sm:px-8 lg:px-12'>
                    <Header />
                        {children}
                    <Footer />
                </div>
            </div>
        </React.StrictMode>
    )
}

export default layout

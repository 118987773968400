const resume = [
    {
        company: 'Markt.inc',
        title: 'Full Stack Web Engineer',
        logo: '/storage/images/marktinc.png',
        start: '2021',
        end: 'Present'
    }
]

export default resume
import React from 'react'
import CircleContainer from './CircleContainer'
import achievements from '../data/achievements'

const timeline = () => (
    <>
        {achievements.map((achievement, achievementIdx) => (
            <div className="relative pb-8" key={achievementIdx}>
                {achievementIdx !== achievements.length - 1 ?
                    ( <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-zinc-500" aria-hidden="true" /> )
                    :
                    null
                }
                <div className="relative flex space-x-4">
                    <CircleContainer className='w-8 h-8 bg-white'>
                        {achievement.icon}
                    </CircleContainer>
                    <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                        <p className="text-sm text-zinc-600 dark:text-zinc-400">
                            {achievement.desc}
                        </p>
                        <div className="whitespace-nowrap text-right text-sm text-zinc-600 dark:text-zinc-400">
                            <time>{achievement.date}</time>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </>
)

export default timeline
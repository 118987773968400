import React from 'react'
import { Link } from "react-router-dom"

interface Props {
    large?: boolean,
    className?: string
}

const avatar = ({
    large = false,
    className,
    ...props
} : Props) => (
    <Link
        to="/"
        className={`${className?? ''} pointer-events-auto`}
        {...props}
    >
        <img
            src='/storage/images/ava.jpg'
            alt="home"
            className={`
                ${large ? 'h-16 w-16' : 'h-9 w-9'}
                rounded-full bg-zinc-100 object-cover dark:bg-zinc-800
            `}
        />
    </Link>
)

export default avatar
import React from 'react'
import { FiDollarSign } from 'react-icons/fi'
import { BsCart3, BsBoxSeam, BsTruck, BsCardText, BsCalendarCheck } from 'react-icons/bs'
import { GoLightBulb } from 'react-icons/go'
import { AiOutlineMail, AiOutlineRobot } from 'react-icons/ai'

const projects = [
    {
        icon: <FiDollarSign className='w-full text-zinc-600 dark:text-white' />,
        title: 'Point of Sale',
        description: 'Streamlines transactions with customers and suppliers while managing inventory and finances.'
    },
    {
        icon: <BsTruck className='w-full text-zinc-600 dark:text-white' />,
        title: 'Supply Chain Tracking',
        description: 'Provides a comprehensive view of item movements as they progress through different production stages across multiple subsidiaries.'
    },
    {
        icon: <BsBoxSeam className='w-full text-zinc-600 dark:text-white' />,
        title: 'Inventory Tracking',
        description: 'Tracks transactions between business, suppliers and resellers. Also manages the deliveries of products.'
    },
    {
        icon: <BsCart3 className='w-full text-zinc-600 dark:text-white' />,
        title: 'Ecommerce',
        description: 'Facilitates seamless online transactions, tracks orders, and offers valuable sales insights to businesses.'
    },
    {
        icon: <BsCardText className='w-full text-zinc-600 dark:text-white' />,
        title: 'Company Profile',
        description: 'Enables businesses to customize their content with a user-friendly CMS, providing admins with the flexibility to update and manage webpages as needed.'
    },
    {
        icon: <GoLightBulb className='w-full text-zinc-600 dark:text-white' />,
        title: 'Business Solution',
        description: 'Offers a tailor-made application with authentication and role-based access control that meets the unique needs of your business.'
    },
    {
        icon: <BsCalendarCheck className='w-full text-zinc-600 dark:text-white' />,
        title: 'Attendance App',
        description: 'Simple employee attendance tracking utilizing face recognition.'
    },
    {
        icon: <AiOutlineMail className='w-full text-zinc-600 dark:text-white' />,
        title: 'Electronic Direct Mail',
        description: 'Empowers businesses to reach their target audience directly through personalized email messages, promoting their brand and products.'
    },
    {
        icon: <AiOutlineRobot className='w-full text-zinc-600 dark:text-white' />,
        title: 'Chat Bot',
        description: 'Offers a flexible and scalable chat bot solution that can take orders and respond to inquiries.'
    }
]

export default projects
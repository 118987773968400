import React, { PropsWithChildren } from 'react'

interface Props {
    className?: string
    href: string
    Icon: any
}

const aboutSocialLink = ({
    className,
    href,
    children,
    Icon
} : PropsWithChildren<Props>) => (
    <li className={`${className} flex items-center`}>
        <a
            href={href}
            className="group flex items-center text-sm font-medium text-zinc-800 transition hover:text-teal-500 dark:text-zinc-200 dark:hover:text-teal-500"
        >
            <Icon className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-teal-500" />
            <span className="ml-4">{children}</span>
        </a>
    </li>
)

export default aboutSocialLink
import React from 'react'
import { BriefcaseIcon } from './Icons'
import CircleContainer from './CircleContainer'
import experiences from '../data/experiences'

const experiencesComponent = () => (
    <div className="rounded-2xl border border-zinc-300 p-6 dark:border-zinc-700/40">
        <h2 className="flex items-center space-x-3 text-sm font-semibold text-zinc-900 dark:text-zinc-100">
            <BriefcaseIcon className="h-6 w-6" />
            <span>Experiences</span>
        </h2>
        <ol className="mt-6 space-y-6">
            {experiences.map((role : Record<string, any>, roleIndex : number) => (
                <li key={roleIndex} className="flex gap-4 items-center">
                    <CircleContainer className='shrink-0 w-10 h-10' >
                        <img src={role.logo} alt="" className="h-8 w-8 rounded-full" />
                    </CircleContainer>
                    <dl className="flex flex-auto flex-wrap gap-x-2">
                        <dt className="sr-only">Organization</dt>
                        <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                            {role.org}
                        </dd>
                        <dt className="sr-only">Role</dt>
                        <dd className="text-xs text-zinc-500 dark:text-zinc-400">
                            {role.title}
                        </dd>
                        <dt className="sr-only">Date</dt>
                        <dd
                            className="ml-auto text-xs text-zinc-400 dark:text-zinc-500"
                            aria-label={`${role.start} until ${role.end}`}
                        >
                            <time dateTime={role.start}>
                                {role.start}
                            </time>
                            {' '}<span aria-hidden="true">—</span>{' '}
                            <time dateTime={role.end}>
                                {role.end}
                            </time>
                        </dd>
                    </dl>
                </li>
            ))}
        </ol>
    </div>
)

export default experiencesComponent
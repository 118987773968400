import React from 'react'
import PageLayout from '../Layouts/PageLayout'
import Container from '../Components/Container'
import Card from '../Components/Card'
import CircleContainer from '../Components/CircleContainer'
import projects from '../data/projects'

const projectsPage = () => (
    <PageLayout
        title='Projects'
    >
        <Container>
            <div className='max-w-2xl'>
                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    The creations I've made for my clients so far.
                </h1>
                <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                    I've been fortunate enough to work on a diverse range of projects. While I can't
                    publicly showcase every project I've completed, I'm happy to provide demos upon request.
                    Here's a taste of the types of programs I've had the pleasure of bringing to life.
                </p>
            </div>
            <div className="mt-16 sm:mt-20 grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                {projects.map((project, projectIdx) => (
                    <Card
                        key={projectIdx}
                        shadowInset='-inset-x-4 -inset-y-6 sm:-inset-x-6'
                    >
                        <div className='flex flex-col'>
                            <CircleContainer className='w-10 h-10'>
                                {project.icon}
                            </CircleContainer>
                            <h2 className='mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100'>
                                {project.title}
                            </h2>
                            <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                {project.description}
                            </p>
                        </div>
                    </Card>
                ))}
            </div>
        </Container>
    </PageLayout>
)

export default projectsPage
const techStacks = [
    {
        name: 'Laravel',
        logo: '/storage/images/laravel.png'
    },
    {
        name: 'React.js',
        logo: '/storage/images/react.png'
    },
    {
        name: 'Vue.js',
        logo: '/storage/images/vue.png'
    },
    {
        name: 'Tailwind css',
        logo: '/storage/images/tailwind.png'
    }
]

export default techStacks
import React from 'react'
import { ArrowDownIcon, BriefcaseIcon } from './Icons'
import Button from './Button'
import resume from '../data/resume'
import CircleContainer from './CircleContainer'

const resumeComponent = () => (
    <div className="rounded-2xl border border-zinc-300 p-6 dark:border-zinc-700/40">
        <h2 className="flex items-center space-x-3 text-sm font-semibold text-zinc-900 dark:text-zinc-100">
            <BriefcaseIcon className="h-6 w-6" />
            <span>Work</span>
        </h2>
        <div className="mt-6 space-y-4">
            {resume.map((role, roleIndex) => (
                <div key={roleIndex} className="flex gap-4 items-center">
                    <CircleContainer className='shrink-0 w-10 h-10' >
                        <img src={role.logo} alt="" className="h-8 w-8 rounded-full" />
                    </CircleContainer>
                    <div className="flex flex-auto flex-wrap gap-x-2">
                        <dt className="sr-only">Company</dt>
                        <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                            {role.company}
                        </dd>
                        <dt className="sr-only">Role</dt>
                        <dd className="text-xs text-zinc-500 dark:text-zinc-400">
                            {role.title}
                        </dd>
                        <dt className="sr-only">Date</dt>
                        <dd
                            className="ml-auto text-xs text-zinc-400 dark:text-zinc-500"
                            aria-label={`${role.start} until ${role.end}`}
                        >
                            <time dateTime={role.start}>
                                {role.start}
                            </time>
                            {' '}<span aria-hidden="true">—</span>{' '}
                            <time dateTime={role.end}>
                                {role.end}
                            </time>
                        </dd>
                    </div>
                </div>
            ))}
        </div>
        <Button href="/storage/files/alvin-resume-2023_(1).pdf" variant="secondary" className="group mt-6 w-full" download>
            Download CV
            <ArrowDownIcon className="h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50" />
        </Button>
    </div>
)

export default resumeComponent
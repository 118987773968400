import React, { PropsWithChildren } from 'react'

interface Props {
    variant: string
    className: string
    href: string
    download?: boolean
}

const variantStyles = {
    primary:
        'bg-zinc-800 font-semibold text-zinc-100 hover:bg-zinc-700 active:bg-zinc-800 active:text-zinc-100/70 dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 dark:active:text-zinc-100/70',
    secondary:
        'bg-zinc-50 font-medium border border-zinc-300 text-zinc-900 hover:bg-zinc-100 active:bg-zinc-100 active:text-zinc-900/60 dark:border-0 dark:bg-zinc-800/50 dark:text-zinc-300 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 dark:active:bg-zinc-800/50 dark:active:text-zinc-50/70',
} as Record<string,any>

const button = ({
    variant = 'primary',
    className,
    href,
    children,
    ...props
} : PropsWithChildren<Props>) => {

    className = `
        ${className}
        ${variantStyles[variant]}
        inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none
    `

    return href ? (
        <a href={href} className={className} {...props}>
            {children}
        </a>
    )
    : (
        <button className={className} {...props}>
            {children}
        </button>
    )
}

export default button